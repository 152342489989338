import React from "react";

const Header = () => {
    return (
        <header className="header">
            <h1>Gerald Nako</h1>
            <p>Creative Front-End Developer</p>
        </header>
    )
};

export default Header;
