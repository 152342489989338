import React from "react";
import {Helmet} from "react-helmet";
import Header from "./components/molecules/header";

function App() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Gerald Nako</title>
                <meta name="description" content="Creative Front-End Developer"/>
            </Helmet>
            <main className="main-wrapper">
                <Header/>
            </main>
        </React.Fragment>
    );
}

export default App;
